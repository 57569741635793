/**
 * Created by gk-lab on 24.05.18.
 */
/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledPicture = styled.picture`
  display: block;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  user-select: none;

  > img {
    display: block;
    user-select: none;
    min-width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: ${(props) => props.fit};
    object-position: ${(props) => props.pos};
  }
`

const Picture = ({
  image,
  image_md,
  image_sm,
  image_md_p,
  image_md_l,
  image_sm_p,
  image_sm_l,
  ...rest
}) => (
  <StyledPicture {...rest}>
    {/* <source media="(min-width: 650px)" srcSet="img_pink_flowers.jpg" /> */}

    {image && <source media="(min-width: 1360px)" srcSet={image} />}

    {image_md_l && <source media="(min-width: 1023px)" srcSet={image_md_l} />}
    {image_md_p && <source media="(min-width: 767px)" srcSet={image_md_p} />}
    {image_md && <source media="(min-width: 767px)" srcSet={image_md} />}

    {image_sm_l && <source media="(min-width: 480px)" srcSet={image_sm_l} />}
    {image_sm_p && <source media="(min-width: 0)" srcSet={image_sm_p} />}
    {image_sm && <source media="(min-width: 0)" srcSet={image_sm} />}

    {image && <img draggable={false} src={image} alt="Background" />}
  </StyledPicture>
)
export default Picture

Picture.FILLMODE = {
  cover: 'cover',
  contain: 'contain',
  fill: 'fill',
  scaleDown: 'scale-down',
  none: 'none',
}

Picture.POSITION = {
  top: 'top',
  bottom: 'bottom',
  center: 'center',
  left: 'left',
  right: 'right',
}

Picture.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  image_md: PropTypes.string,
  image_md_l: PropTypes.string,
  image_md_p: PropTypes.string,
  image_sm: PropTypes.string,
  image_sm_l: PropTypes.string,
  image_sm_p: PropTypes.string,
  contain: PropTypes.bool,
  fit: PropTypes.oneOf(Object.values(Picture.FILLMODE)),
  pos: PropTypes.oneOf(Object.values(Picture.POSITION)),
}

Picture.defaultProps = {
  className: '',
  image: undefined,
  image_md: undefined,
  image_md_l: undefined,
  image_md_p: undefined,
  image_sm: undefined,
  image_sm_l: undefined,
  image_sm_p: undefined,
  contain: false,
  fit: Picture.FILLMODE.cover,
  pos: Picture.POSITION.top,
}
