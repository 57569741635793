const Colors = {
  black: '#000',
  white: '#fff',
  dark: '#936a45',
  light: '#f6f6f8',
  accent: '#e7d3b3',
  primary: '#e7d3b3',
  secondary: 'rgba(38, 38, 38, 1)',
}

export default Colors
