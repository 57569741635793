import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { saveJson, useAppDispatch } from 'context/AppContext'

const KirbyLoaderV2 = ({ children, url }) => {
  const [isLoading, setIsLoading] = useState(true)
  const appDispatch = useAppDispatch()

  const onJsonLoaded = (json) => {
    appDispatch(saveJson(json))
    setIsLoading(false)
  }

  const loadPageData = () => {
    setIsLoading(true)
    // eslint-disable-next-line
    fetch(url)
      .then((response) => response.json())
      .then((json) => {
        if (json[0]) {
          onJsonLoaded(json[0])
        } else {
          onJsonLoaded(json)
        }
      })
  }

  useEffect(() => {
    loadPageData()
    return () => {}
  }, [url])

  return (
    <>
      {isLoading && <p>loading</p>}
      {!isLoading && children}
    </>
  )
}

KirbyLoaderV2.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  url: PropTypes.string.isRequired,
}

KirbyLoaderV2.defaultProps = {
  children: null,
}

export default KirbyLoaderV2
