/**
 * Created by gk-lab on 14.08.17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import Icon from 'components/icon/Icon'
import Colors from 'Colors'

const StyledIconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.hasChildren ? 'auto' : '3rem')};
  height: ${(props) => (props.hasChildren ? 'auto' : '3rem')};
  outline: 0;
  cursor: pointer;
  background: ${(props) => props.theme.background.normal};
  color: ${(props) => props.theme.color.normal};
  border: 1px solid ${(props) => props.theme.border.normal};
  &:active,
  &:focus {
    background: ${(props) => props.theme.background.active};
    color: ${(props) => props.theme.color.active};
    border: 1px solid ${(props) => props.theme.border.active};
  }
  &:hover {
    background: ${(props) => props.theme.background.hover};
    color: ${(props) => props.theme.color.hover};
    border: 1px solid ${(props) => props.theme.border.normal};
  }
  i {
    margin: 0;
  }
`

const StyledText = styled.span`
  margin-left: 0.5rem;
`

const IconButton = ({
  onClick,
  align,
  icon,
  children,
  theme,
  className,
  ...rest
}) => (
  <ThemeProvider theme={theme}>
    <StyledIconButton
      type="button"
      alignTo={align}
      disabled={!onClick}
      onClick={onClick}
      className={`icon-button ${!children && 'icon-only'} ${className}`}
      hasChildren={children !== null}
      {...rest}
    >
      {icon && <Icon icon={icon} />}
      {children && <StyledText>{children}</StyledText>}
    </StyledIconButton>
  </ThemeProvider>
)

export default IconButton

IconButton.DefaultTheme = {
  border: {
    normal: 'transparent',
    hover: 'transparent',
    active: 'transparent',
  },
  color: {
    normal: Colors.white,
    hover: Colors.accent,
    active: Colors.dark,
  },
  background: {
    normal: 'rgba(20,20,20,.5)',
    hover: Colors.white,
    active: 'rgba(20,20,20,.5)',
  },
}

IconButton.LightTheme = {
  border: {
    normal: 'transparent',
    hover: 'transparent',
    active: 'transparent',
  },
  color: {
    normal: Colors.dark,
    hover: Colors.accent,
    active: Colors.accent,
  },
  background: {
    normal: Colors.white,
    hover: Colors.white,
    active: Colors.white,
  },
}

IconButton.propTypes = {
  align: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.shape({
    color: PropTypes.object,
    background: PropTypes.object,
  }),
}

IconButton.defaultProps = {
  align: '',
  className: '',
  children: null,
  icon: '',
  onClick: () => {},
  theme: IconButton.DefaultTheme,
}
