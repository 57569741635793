import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useLocation } from 'react-router'
import {
  initialize,
  setActiveUrl,
  setIsScrolling,
  updateScrollPos,
  useScrollToDispatch,
  useScrollToState,
} from '../ScrollToContext/ScrollToContext'

const StyledScrollToContainer = styled.div``

const ScrollToContainer = ({ children, ...rest }) => {
  const mainRef = useRef(null)
  const scrollRef = useRef(null)

  const scrollToDispatch = useScrollToDispatch()
  const { scrollTo, isScrolling } = useScrollToState()
  const location = useLocation()

  let int = -1

  const onScroll = () => {
    clearInterval(int)
    int = setInterval(stopScroll, 1000)
    if (!isScrolling) {
      scrollToDispatch(setIsScrolling(true))
    }
    scrollToDispatch(updateScrollPos())
  }

  const stopScroll = () => {
    clearInterval(int)
    // console.log(' - stopScroll: ')
    scrollToDispatch(setIsScrolling(false))
    scrollToDispatch(updateScrollPos())
  }

  /**
   * Init context, references and scroll listener
   */
  useEffect(() => {
    // console.log('useEffect - init: ')
    if (mainRef && mainRef.current && scrollRef && scrollRef.current) {
      mainRef.current.addEventListener('scroll', onScroll)
      mainRef.current.addEventListener('touchmove', onScroll)
      scrollToDispatch(initialize(mainRef, scrollRef))
    }
    return () => {
      if (mainRef && mainRef.current) {
        mainRef.current.removeEventListener('scroll', onScroll)
        mainRef.current.removeEventListener('touchmove', onScroll)
      }
    }
  }, [mainRef, mainRef.current, scrollRef, scrollRef.current])

  /**
   * Change hash on scroll
   */
  useEffect(() => {
    //console.log('hash update - : ', location.pathname, location.hash, scrollUrl)
    const cleanHash = location.hash.split('#').join('')
    if (location.pathname === '/') {
      // console.log('cleanHash - : ', cleanHash)
      if (cleanHash) {
        scrollToDispatch(setActiveUrl(cleanHash))
      } else {
        scrollToDispatch(setActiveUrl(''))
      }
    }
    return () => {}
  }, [location.hash, location.pathname])

  useEffect(() => {
    // console.log('useEffect scrollTo changed! - : ', mainRef && mainRef.current)
    if (mainRef && mainRef.current && scrollTo >= 0) {
      mainRef.current.scrollTo({
        top: scrollTo,
        behavior: 'smooth',
      })
    }
    return () => {}
  }, [scrollTo, mainRef, mainRef.current])

  return (
    <StyledScrollToContainer ref={mainRef} {...rest}>
      <div ref={scrollRef}>{children}</div>
    </StyledScrollToContainer>
  )
}

ScrollToContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

ScrollToContainer.defaultProps = {
  children: null,
}

export default ScrollToContainer
