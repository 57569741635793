function IsScrolledIntoView(el) {
  if (el) {
    const rect = el.getBoundingClientRect()
    const elemTop = rect.top
    const elemBottom = rect.bottom
    // console.log('ScrollToReducer - into -: ', elemTop, elemBottom, (elemBottom - elemTop))
    if (elemBottom - elemTop >= window.innerHeight * 0.25) {
      const isInto =
        elemTop <= window.innerHeight && elemBottom >= window.innerHeight * 0.5
      return isInto
    }
  }
  return false
}

function IsIntoView(el) {
  if (el) {
    const rect = el.getBoundingClientRect()
    const offset = window.innerHeight * 0.7
    const elemTop = rect.top + offset
    const elemBottom = rect.bottom
    const isInView =
      elemTop <= window.innerHeight &&
      elemBottom >= 0 &&
      elemBottom >= window.innerHeight
    console.log(
      'IsIntoView: ',
      rect,
      elemTop,
      elemBottom,
      elemBottom > window.innerHeight,
      isInView
    )
    return isInView
  }
  return false
}

export { IsScrolledIntoView, IsIntoView }
