/**
 * Created by gk-lab on 24.04.18.
 */
/* eslint-disable react/forbid-prop-types */

import React, { useState, useEffect } from 'react'
import IconButton from 'components/icon-button/IconButton'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { setNavIsOpen, useAppDispatch, useAppState } from 'context/AppContext'
import { useScrollToState } from 'sectionsrenderer'
import Icon from '../icon/Icon'

const StyledBackground = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  transition: opacity 0.2s;
  pointer-events: auto;
  display: none;
  @media (max-width: ${(props) => props.theme.breakpoint.navIsOverlay}) {
    display: ${(props) => (props.isOpen ? 'inline' : 'none')};
  }
`

const StyledNavigationContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background-color: ${(props) => props.theme.background};
  display: flex;
  flex-direction: column;
  min-width: ${(props) => props.theme.nav.width.open};
  transition: min-width 0.2s, max-width 0.2s;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1;

  @media (max-width: ${(props) => props.theme.breakpoint.navAlwaysOpen}) {
    position: relative;
    pointer-events: auto;
    min-width: ${(props) =>
      props.isOpen ? props.theme.nav.width.open : props.theme.nav.width.closed};
    max-width: ${(props) =>
      props.isOpen ? props.theme.nav.width.open : props.theme.nav.width.closed};
  }

  @media (max-width: ${(props) => props.theme.breakpoint.navIsOverlay}) {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: auto;
    min-width: ${(props) =>
      props.isOpen ? props.theme.nav.width.open : props.theme.nav.width.closed};
    max-width: ${(props) =>
      props.isOpen ? props.theme.nav.width.open : props.theme.nav.width.closed};
    z-index: 1;
  }
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: auto;
    max-height: ${(props) => (props.isOpen ? 'unset' : '4rem')};
    z-index: 1;
  }
`

const StyledContent = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.secondary};
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  min-width: ${(props) => props.theme.nav.width.open};
  max-width: ${(props) => props.theme.nav.width.open};
  overflow-x: hidden;
  transition: min-width 0.2s, max-width 0.2s;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  @media (max-width: ${(props) => props.theme.breakpoint.navAlwaysOpen}) {
    position: fixed;
    min-width: ${(props) =>
      props.isOpen ? props.theme.nav.width.open : props.theme.nav.width.closed};
    max-width: ${(props) =>
      props.isOpen ? props.theme.nav.width.open : props.theme.nav.width.closed};
  }
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    background-color: ${(props) =>
      props.isOpen ? props.theme.background : 'transparent'};
    min-width: 0;
    padding: 4rem 0;
    overflow-x: hidden;
    overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
  }
`

const StyledLogo = styled.img`
  display: flex;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  box-sizing: border-box;
  cursor: pointer;
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: #000;
  }
`

const StyledNavigation = styled.nav`
  margin: 1rem 0;
  opacity: 1;
  transition: opacity 0.2s;
  width: 100%;
  display: inline-block;
  list-style: none;
  pointer-events: auto;
  @media (max-width: ${(props) => props.theme.breakpoint.navAlwaysOpen}) {
    pointer-events: ${(props) => (!props.isOpen ? 'none' : 'auto')};
    opacity: ${(props) => (props.isOpen ? 1 : 0)};
  }
`
const StyledListItem = styled.li``

const StyledIconButtonContainer = styled.div`
  width: 4rem;
  height: 4rem;
  align-self: flex-end;
  visibility: hidden;
  @media (max-width: ${(props) => props.theme.breakpoint.navAlwaysOpen}) {
    visibility: visible;
  }
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    display: none;
  }
`

const StyledIconButton = styled(IconButton)`
  width: 4rem;
  height: 4rem;
`

const StyledSpacer = styled.div`
  flex-grow: 2;
`

const StyledButton = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  color: ${(props) =>
    props.isActive ? props.theme.accent : props.theme.white};
  background-color: ${(props) =>
    props.isActive ? props.theme.black : props.theme.secondary};
  display: inline-block;
  font-size: 1.75rem;
  cursor: pointer;
  margin: 0 0 0 1rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 1rem 2rem 1rem 1rem;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  border: 0;
  outline: 0;
  white-space: nowrap;

  &:hover {
    color: ${(props) => props.theme.accent};
    background-color: ${(props) =>
      props.isActive ? props.theme.black : props.theme.white};
  }

  &:active,
  &.active {
    color: ${(props) => props.theme.accent};
    background-color: ${(props) => props.theme.black};
  }
`
const StyledButtonText = styled.span``

const Navigation = () => {
  const { navigation } = useAppState()
  const { scrollUrl } = useScrollToState()
  const [items, setItems] = useState([])
  const [open, setOpen] = useState(false)
  const appDispatch = useAppDispatch()

  const onClick = (url) => {
    onToggle()
  }

  const onToggle = () => {
    setOpen(!open)
  }

  useEffect(() => {
    appDispatch(setNavIsOpen(open))
    return () => {}
  }, [open])

  useEffect(() => {
    if (navigation) {
      setItems(
        navigation.map((link, index) => {
          const { title, url } = link
          return (
            <StyledListItem key={index.toString()}>
              <StyledButton
                to={`#${url}`}
                key={`navbutton-${index.toString()}`}
                onClick={() => onClick(url)}
                isActive={scrollUrl ? url === scrollUrl : index === 0}
              >
                <StyledButtonText>{title}</StyledButtonText>
              </StyledButton>
            </StyledListItem>
          )
        })
      )
    }
    return () => {}
  }, [navigation, scrollUrl])

  return (
    <StyledNavigationContainer isOpen={open} onClick={onToggle}>
      <StyledBackground isOpen={open} />
      <StyledContent isOpen={open}>
        <StyledLogo id="menu" src="/img/musicmonks-menu.png" alt="mm-logo" />
        <StyledNavigation isOpen={open}>{items}</StyledNavigation>
        <StyledSpacer />
        <StyledIconButtonContainer>
          <StyledIconButton
            icon={open ? Icon.ICONS.ARROW_LEFT : Icon.ICONS.ARROW_RIGHT}
            onClick={(e) => {
              if (e) e.stopPropagation()
              onToggle()
            }}
            theme={{
              ...IconButton.DefaultTheme,
              border: {
                normal: 'transparent',
                hover: 'transparent',
                active: 'transparent',
              },
            }}
          />
        </StyledIconButtonContainer>
      </StyledContent>
    </StyledNavigationContainer>
  )
}

Navigation.propTypes = {}
Navigation.defaultProps = {}

export default Navigation
