/**
 * Created by gk-lab on 01.08.17.
 */
/* eslint-disable react/no-danger */
/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import Icon from 'components/icon/Icon'
import styled, { ThemeProvider, css } from 'styled-components'
import Colors from 'Colors'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'
import { generatePath } from 'react-router'
import routeNames from 'routeNames'
import Video from '../video/Video'

const previewPostStyle = css`
  cursor: pointer;
`

const StyledPost = styled.div`
  width: 100%;
  pointer-events: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content: flex-start;
  box-sizing: border-box;
  ${(props) => !props.isDetail && props.hasContent && previewPostStyle}
`

const StyledTitle = styled.h3`
  text-align: left;
  padding: 1.5rem 1rem 0.5rem 1rem;
  margin: 0;
  width: 100%;
`

const StyledPreview = styled.div`
  display: flex;
  flex-direction: row;
  flex-direction: ${(props) =>
    props.postIndex !== -1 && props.postIndex % 2 === 0
      ? 'row'
      : 'row-reverse'};
  width: 100%;
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    flex-direction: column;
  }
`

const StyledDetail = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  img {
    margin: 1rem 0;
  }
`

const StyledDetailTitle = styled(StyledTitle)`
  margin: 1.5rem 0;
`

const StyledDetailText = styled.div`
  a,
  button {
    pointer-events: auto;
  }
  color: ${(props) => props.theme.color.normal};
  h2,
  h3,
  h4,
  h5 {
    color: ${(props) => props.theme.color.normal} !important;
    &:hover {
      color: ${(props) => props.theme.color.normal} !important;
    }
  }
`

const StyledThumb = styled.div`
  background-image: url(${(props) => props.thumbImage});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  max-width: ${(props) =>
    (props.theme.thumb && props.theme.thumb.width) || '33%'};
  min-width: ${(props) =>
    (props.theme.thumb && props.theme.thumb.width) || '33%'};
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    padding-top: 60%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
    background-size: cover;
  }
`

const StyledDetailThumb = styled.img`
  max-width: 100%;
  min-width: 100%;
`

const StyledTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  padding: 0 1rem;
`

const StyledShortText = styled.div`
  padding: 0.5rem 1rem;
`

const buttonStyle = css`
  display: flex;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  width: fit-content;
  color: ${(props) => props.theme.button.color.normal};
  background-color: ${(props) => props.theme.button.background.normal};
  cursor: pointer;

  text-align: left;
  justify-content: flex-start;
  align-items: center;

  padding: 0.5rem;
  margin: 0 0.25rem 0.25rem 0;

  &:hover {
    outline: 0;
    color: ${(props) => props.theme.button.color.hover};
    background-color: ${(props) => props.theme.button.background.hover};
    svg {
      fill: ${(props) => props.theme.button.color.hover};
    }
  }
  &.active,
  &:active,
  &:focus {
    outline: 0;
    color: ${(props) => props.theme.button.color.active};
    background-color: ${(props) => props.theme.button.background.active};
    svg {
      fill: ${(props) => props.theme.button.color.active};
    }
  }
`

const StyledPlayIcon = styled(Icon)`
  height: 2rem;
  width: 2rem;
`

const StyledButton = styled.button`
  ${buttonStyle}
`

const StyledLink = styled(Link)`
  ${buttonStyle}
`

const StyledIcon = styled(Icon)`
  font-size: 2rem;
  color: inherit;
  margin-right: 0.5rem;
  padding: 2px 0;
`
const StyledButtonText = styled.span`
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-transform: uppercase;
`

const Post = ({
  url,
  detail,
  title,
  shorttext,
  text,
  youtube,
  youtubethumb,
  disclaimer,
  thumb,
  onClick,
  btn,
  deeplinks,
  onDeepClick,
  theme,
  postIndex,
  hasContent,
  ...rest
}) => {
  const history = useHistory()

  const postProbs = {
    id: url,
    hasContent,
    isDetail: detail,
    hasThumb: thumb,
    onClick: !detail && hasContent && onClick ? () => onClick(url) : () => {},
    ...rest,
  }

  const previewText = shorttext === '' && text !== '' ? text : shorttext

  const deepButtons =
    deeplinks && deeplinks.length > 0
      ? deeplinks.map((link, idx) => {
          if (link.url.indexOf('http') === 0) {
            return (
              <StyledButton
                type="button"
                key={`dl_${idx.toString()}`}
                onClick={(e) => {
                  e.stopPropagation()
                  window.open(link.url, link.target || '_blank')
                }}
              >
                {link.icon !== 'none' && <StyledIcon icon={link.icon} />}
                <StyledButtonText>
                  {link.target === '_self' && link.icon === 'none' && '#'}
                  {link.title}
                </StyledButtonText>
              </StyledButton>
            )
          }

          const splitHash = link.url.split('#')
          const hash = splitHash.length === 2 ? splitHash[1] : ''
          const splitUrl = splitHash[0].split('/')

          while (splitUrl.length > 1 && splitUrl[0] === '') {
            splitUrl.shift()
          }

          const page1 = splitUrl[0]
          const page2 = splitUrl[1] || ''

          let to = { pathName: '/', hash }
          if (page2) {
            to = {
              pathName: generatePath(routeNames.page2, { page1, page2 }),
              hash,
            }
          } else if (page1) {
            to = {
              pathName: generatePath(routeNames.page1, { page1 }),
              hash,
            }
          }

          return (
            <StyledLink
              to={'#'}
              onClick={(e) => {
                e.preventDefault()
                onDeepClick()
                history.push(`${to.pathName}#${to.hash}`)
              }}
              key={`dl_${idx.toString()}`}
            >
              {link.icon !== 'none' && <StyledIcon icon={link.icon} />}
              <StyledButtonText>
                {link.target === '_self' && link.icon === 'none' && '#'}
                {link.title}
              </StyledButtonText>
            </StyledLink>
          )
        })
      : null

  const getVideo = () => {
    return (
      <Video youtube={youtube} thumb={youtubethumb} disclaimer={disclaimer}>
        <StyledPlayIcon icon={Icon.ICONS.PLAY} size={48} />
        Video laden
      </Video>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledPost
        {...postProbs}
        className={`post ${detail ? ' detail' : ''} ${
          hasContent ? ' content' : ''
        }`}
      >
        {!detail && (
          <StyledPreview postIndex={postIndex} className="post-preview">
            {thumb && <StyledThumb thumbImage={thumb} alt={title} />}
            <StyledTextContainer className="post-text-con">
              <StyledTitle className="post-title">{title}</StyledTitle>
              <StyledShortText
                className="post-text"
                dangerouslySetInnerHTML={{ __html: previewText }}
              />
              {youtube && getVideo()}
              {deepButtons}
            </StyledTextContainer>
          </StyledPreview>
        )}
        {detail && (
          <StyledDetail className="post-detail">
            <StyledDetailTitle>{title}</StyledDetailTitle>
            {thumb && (
              <StyledDetailThumb
                className="post-thumb"
                src={thumb}
                alt={title}
              />
            )}
            <StyledDetailText
              className="post-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            {youtube && getVideo()}
          </StyledDetail>
        )}

        {btn}
      </StyledPost>
    </ThemeProvider>
  )
}

export default Post

Post.DefaultTheme = {
  maxWidth: '768px',
  background: {
    normal: 'rgba(0,0,0,.9)',
    hover: Colors.black,
  },
  color: {
    normal: Colors.white,
    hover: Colors.accent,
  },
  button: {
    background: {
      normal: 'transparent',
      hover: Colors.white,
      active: Colors.white,
    },
    color: {
      normal: Colors.white,
      hover: Colors.black,
      active: Colors.accent,
    },
  },
  thumb: {
    width: '33%',
  },
}

Post.propTypes = {
  btn: PropTypes.node,
  deeplinks: PropTypes.arrayOf(PropTypes.object),
  detail: PropTypes.bool,
  hasContent: PropTypes.bool,
  onClick: PropTypes.func,
  onDeepClick: PropTypes.func,
  shorttext: PropTypes.string,
  text: PropTypes.string,
  youtube: PropTypes.string,
  youtubethumb: PropTypes.string,
  disclaimer: PropTypes.object,
  thumb: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  postIndex: PropTypes.number,
  theme: PropTypes.object,
}

Post.defaultProps = {
  btn: '',
  deeplinks: [],
  detail: false,
  hasContent: false,
  onClick: undefined,
  onDeepClick: () => {},
  shorttext: '',
  text: '',
  youtube: '',
  youtubethumb: '',
  disclaimer: {},
  thumb: '',
  title: '',
  url: '',
  postIndex: -1,
  theme: Post.DefaultTheme,
}
