/**
 * Created by gk-lab on 01.08.17.
 */
/* eslint-disable import/no-unresolved */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Icon from 'components/icon/Icon'
import { Link } from 'react-router-dom'

const StyledButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  outline: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 1.5rem;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    background-color: rgba(255, 255, 255, 1);
    color: #000;
    svg {
      fill: black;
    }
  }
  svg {
    fill: white;
  }
`
const StyledLink = styled(Link)``
const StyledText = styled.span`
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
`
const StyledIcon = styled(Icon)`
  margin-left: -0.5rem;
`

const Button = ({ children, link, text, size, ...rest }) => {
  if (link) {
    return (
      <StyledLink to={link} {...rest}>
        <StyledText>{children}</StyledText>
      </StyledLink>
    )
  }
  if (text) {
    return (
      <StyledButton type="button" {...rest}>
        <StyledIcon icon={Icon.ICONS.ARROW_RIGHT} />
        <StyledText>{text}</StyledText>
      </StyledButton>
    )
  }
  return (
    <StyledButton type="button" {...rest}>
      <StyledText>{children}</StyledText>
    </StyledButton>
  )
}

export default Button

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  link: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string,
}

Button.defaultProps = {
  children: null,
  link: '',
  size: '',
  text: '',
}
