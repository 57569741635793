import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router'
import SocialButton from 'components/social-button/SocialButton'
import IconButton from 'components/icon-button/IconButton'
import yticon from 'assets/img/icon-yt.png'
import fbicon from 'assets/img/icon-fb.png'
import styled, { ThemeProvider } from 'styled-components'
import Colors from 'Colors'
import {
  setScrollTo,
  useScrollToDispatch,
  useScrollToState,
} from 'sectionsrenderer'
import Icon from '../icon/Icon'
import { setIsScrolling } from '../../sectionsrenderer/ScrollToContext/ScrollToContext'

export const ANI_DIR = {
  LEFT: 'left',
  RIGHT: 'right',
}

const StyledMeta = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: ${(props) => props.theme.appMaxWidth};
  margin: 0 auto;
  padding: 1rem 1.5rem;
  box-sizing: border-box;
  z-index: 666;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: row;
    padding: 0.25rem 0.5rem;
  }
`

const StyledSocialButton = styled(SocialButton)`
  margin: 0.25rem;
`

const StyledIconButton = styled(IconButton)`
  margin: 0.25rem;
`

const StyledScrollToTop = styled(StyledIconButton)`
  position: relative;
  display: flex;
  width: 3rem;
  height: 3rem;
  pointer-events: all;
  background-color: ${(props) => props.theme.toTop.background.normal};
  color: ${(props) => props.theme.toTop.color.normal};
  svg {
    fill: #000;
  }
  &:hover {
    background-color: ${(props) => props.theme.toTop.background.hover};
    color: ${(props) => props.theme.toTop.color.hover};
    svg {
      fill: #fff;
    }
  }
`

const StyledArrow = styled.i`
  position: absolute;
  left: 0;
  right: 0;
`
const StyledArrow1 = styled(StyledArrow)`
  top: 0.5rem;
`
const StyledArrow2 = styled(StyledArrow)`
  bottom: 0.25rem;
`

const Meta = ({ isVisible, theme, ...rest }) => {
  const [showToTop, setShowToTop] = useState(false)
  const scrollToDispatch = useScrollToDispatch()
  const { scrollPc } = useScrollToState()
  const history = useHistory()
  const onScrollToTop = () => {
    // scrollToDispatch(setScrollTo(0))
    history.replace(`#`)
    scrollToDispatch(setIsScrolling(true))
    scrollToDispatch(setScrollTo(1))
  }

  useEffect(() => {
    setShowToTop(scrollPc > 0.8)
    return () => {}
  }, [scrollPc])

  return (
    <ThemeProvider theme={theme}>
      {isVisible && (
        <StyledMeta {...rest}>
          {showToTop && (
            <StyledScrollToTop onClick={onScrollToTop}>
              <span>
                <StyledArrow1>
                  <Icon icon={Icon.ICONS.ARROW_UP} size={24} />
                </StyledArrow1>
                <StyledArrow2>
                  <Icon icon={Icon.ICONS.ARROW_UP} size={24} />
                </StyledArrow2>
              </span>
            </StyledScrollToTop>
          )}

          <StyledSocialButton
            imgSrc={fbicon}
            text="facebook"
            href="https://www.facebook.com/musicmonks"
            animationDir={ANI_DIR.LEFT}
          />
          <StyledSocialButton
            imgSrc={yticon}
            text="youtube"
            href="https://www.youtube.com/user/SeeedTribute"
            animationDir={ANI_DIR.LEFT}
          />
          {/*<SocialButton
          imgSrc={twicon}
          text="twitter"
          href="https://twitter.com/nu_crossmetal"
          animationDir={ANI_DIR.RIGHT}
        />*/}
          {/*<SocialButton
          imgSrc={igicon}
          text="instagram"
          href="https://www.instagram.com/musicmonks_/"
          animationDir={ANI_DIR.RIGHT}
        />*/}
        </StyledMeta>
      )}
    </ThemeProvider>
  )
}

Meta.propTypes = {
  isVisible: PropTypes.bool,
  theme: PropTypes.object,
}

Meta.defaultProps = {
  isVisible: true,
  theme: {
    toTop: {
      color: {
        normal: Colors.black,
        hover: Colors.accent,
      },
      background: {
        normal: Colors.white,
        hover: Colors.black,
      },
    },
  },
}

export default Meta
