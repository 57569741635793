import React from 'react'
import { IsScrolledIntoView } from '../utils/helper'

const ScrollToStateContext = React.createContext()
const ScrollToDispatchContext = React.createContext()

function useScrollToState() {
  const context = React.useContext(ScrollToStateContext)
  if (context === undefined) {
    throw new Error('useScrollToState must be used within a ScrollToProvider')
  }
  return context
}

function useScrollToDispatch() {
  const context = React.useContext(ScrollToDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useScrollToDispatch must be used within a ScrollToProvider'
    )
  }
  return context
}

const ACTIONS = {
  INITIALIZE: 'INITIALIZE',
  INITIALIZE_SECTION: 'INITIALIZE_SECTION',
  UPDATE_SCROLLPOS: 'UPDATE_SCROLLPOS',
  SET_ACTIVE_URL: 'SET_ACTIVE_URL',
  SET_SCROLLTO: 'SET_SCROLLTO',
  SET_ISSCROLLING: 'SET_ISSCROLLING',
}

const updateScrollPos = () => {
  return {
    type: ACTIONS.UPDATE_SCROLLPOS,
    payload: {},
  }
}

const setScrollTo = (pos) => {
  return {
    type: ACTIONS.SET_SCROLLTO,
    payload: {
      pos,
    },
  }
}

const setActiveUrl = (url) => {
  return {
    type: ACTIONS.SET_ACTIVE_URL,
    payload: {
      url,
    },
  }
}

const initialize = (mainRef, scrollRef) => {
  return {
    type: ACTIONS.INITIALIZE,
    payload: {
      mainRef,
      scrollRef,
    },
  }
}
const initializeSection = (idx, url, ref) => {
  return {
    type: ACTIONS.INITIALIZE_SECTION,
    payload: {
      idx,
      url,
      ref,
    },
  }
}

const setIsScrolling = (value, resetUrl = false) => {
  return {
    type: ACTIONS.SET_ISSCROLLING,
    payload: {
      value,
      resetUrl,
    },
  }
}

function ScrollToReducer(state, action) {
  const { type, payload } = action
  // console.log('ScrollToReducer - type, payload: ', type, payload)
  switch (type) {
    case ACTIONS.INITIALIZE: {
      const { mainRef, scrollRef } = payload
      return {
        ...state,
        mainRef,
        scrollRef,
      }
    }
    case ACTIONS.INITIALIZE_SECTION: {
      const { sectionRefs } = state
      const { idx, url, ref } = payload
      sectionRefs[idx] = { idx, url, ref }
      return {
        ...state,
        sectionRefs,
      }
    }
    case ACTIONS.SET_ACTIVE_URL: {
      const { sectionRefs } = state
      const { url } = payload
      // console.log('ScrollToReducer - ScrollToReducer: ', url, url === '')
      const activeSection =
        url === ''
          ? sectionRefs[0]
          : sectionRefs.find((section) => section.url === url)
      // console.log('ScrollToReducer - SET_ACTIVE_URL: ', state.activeUrl, (activeSection || {}).url)
      return {
        ...state,
        activeUrl: (activeSection || {}).url,
      }
    }
    case ACTIONS.UPDATE_SCROLLPOS: {
      const { scrollRef, mainRef, sectionRefs } = state
      if (
        scrollRef &&
        scrollRef.current &&
        mainRef &&
        mainRef.current &&
        sectionRefs
      ) {
        const scrollRect = scrollRef.current.getBoundingClientRect()
        const mainRect = mainRef.current.getBoundingClientRect()
        const pc = Math.abs(-scrollRect.top / mainRect.height)

        const activeSection = sectionRefs.find((section, idx) => {
          // console.log('ScrollToReducer - into?: ', section.url, IsScrolledIntoView(section.ref, 0))
          return IsScrolledIntoView(section.ref, 0)
        })

        // console.log('ScrollToReducer - UPDATE_SCROLLPOS: ', activeSection)
        return {
          ...state,
          scrollPos: Math.round(scrollRect.top),
          scrollPc: Math.round(pc * 100) / 100,
          scrollUrl: (activeSection || {}).url,
          scrollIdx: (activeSection || {}).idx,
          /*          scrollTo:
            Math.abs(Math.round(scrollTo) + Math.round(scrollRect.top)) < 10
              ? -1
              : scrollTo,*/
        }
      }
      return state
    }
    case ACTIONS.SET_SCROLLTO: {
      const { scrollRef, mainRef } = state
      const { pos } = payload
      const maxScroll =
        scrollRef.current.offsetHeight - mainRef.current.offsetHeight
      const setPos = pos > maxScroll ? maxScroll : pos
      return {
        ...state,
        scrollTo: setPos,
      }
    }
    case ACTIONS.SET_ISSCROLLING: {
      const { value, resetUrl } = payload
      // console.log('ScrollToReducer - SET_ISSCROLLING: ', value)
      return {
        ...state,
        ...(resetUrl && { scrollUrl: '', activeUrl: '' }),
        isScrolling: value,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function ScrollToProvider({ children }) {
  const [state, dispatch] = React.useReducer(ScrollToReducer, {
    mainRef: null,
    scrollRef: null,
    sectionRefs: [],
    scrollPos: 0,
    scrollPc: 0,
    activeUrl: '',
    scrollUrl: '',
    activeIdx: -1,
    scrollIdx: -1,
    scrollTo: -1,
    isScrolling: false,
    animate: false,
  })
  return (
    <ScrollToStateContext.Provider value={state}>
      <ScrollToDispatchContext.Provider value={dispatch}>
        {children}
      </ScrollToDispatchContext.Provider>
    </ScrollToStateContext.Provider>
  )
}

export {
  ScrollToProvider,
  useScrollToState,
  useScrollToDispatch,
  initialize,
  initializeSection,
  setActiveUrl,
  setScrollTo,
  setIsScrolling,
  updateScrollPos,
}
