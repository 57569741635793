/**
 * Created by gk-lab on 14.08.17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import { SVG } from '../svg'

const Icon = ({ icon, size, color, ...rest }) => {
  return (
    <SVG
      data-testid={icon}
      size={size}
      path={Icon.PATHS[icon]}
      fill={color}
      {...rest}
    />
  )
}

Icon.ICONS = {
  HEART: 'heart',
  STAR: 'star',
  MICROPHONE: 'microphone',
  STARCIRCLE: 'starcircle',
  ARROW_RIGHT: 'ARROW_RIGHT',
  ARROW_LEFT: 'ARROW_LEFT',
  ARROW_UP: 'ARROW_UP',
  CLOSE: 'CLOSE',
  LINK: 'link',
  PLAY: 'play_arrow',
  OPEN: 'open_in_new',
  CALENDAR: 'today',
}

Icon.PATHS = {
  [Icon.ICONS.HEART]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/>',
  [Icon.ICONS.MICROPHONE]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"/>',
  [Icon.ICONS.STAR]:
    '<g><rect fill="none" height="24" width="24" x="0"/><polygon points="14.43,10 12,2 9.57,10 2,10 8.18,14.41 5.83,22 12,17.31 18.18,22 15.83,14.41 22,10"/></g>',
  [Icon.ICONS.STARCIRCLE]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm4.24 16L12 15.45 7.77 18l1.12-4.81-3.73-3.23 4.92-.42L12 5l1.92 4.53 4.92.42-3.73 3.23L16.23 18z"/>',
  [Icon.ICONS.ARROW_RIGHT]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/>',
  [Icon.ICONS.ARROW_LEFT]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/>',
  [Icon.ICONS.ARROW_UP]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z"/>',
  [Icon.ICONS.CLOSE]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>',
  [Icon.ICONS.LINK]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/>',
  [Icon.ICONS.PLAY]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M8 5v14l11-7z"/>',
  [Icon.ICONS.CALENDAR]:
    '<path d="M0 0h24v24H0z" fill="none"/><path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/>',
  [Icon.ICONS.OPEN]:
    '<path d="M0 0h24v24H0V0z" fill="none"/><path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>',
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.values(Icon.ICONS)).isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
}

Icon.defaultProps = {
  size: 24,
  color: '#fff',
}

export default Icon
