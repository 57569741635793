/**
 * Created by gk-lab on 01.08.17.
 */
import React, { useEffect, useState } from 'react'
import { Standard, Gallery } from '@gk-lab/react-components'
import Footer from 'pages/footer/Footer'
import Event from 'components/event/Event'
import Post from 'components/post/Post'
// import Gallery from 'components/gallery-v2/Gallery'
import Button from 'components/button/Button'
import PropTypes from 'prop-types'
import Welcome from 'pages/welcome/Welcome'
import styled from 'styled-components'
import { generatePath, useHistory, useLocation } from 'react-router'
import routeNames from 'routeNames'
import themes from './themes'
import theme from 'theme'
import { filterUrl } from 'utils/helper'
import { useAppState } from '../../context/AppContext'
// import WelcomeDDD from '../../pages/welcome/WelcomeDDD'

const StyledPost = styled(Post)`
  border-radius: 0.5rem;
  overflow: hidden;
  // margin: ${(props) => (props.isDetail ? '3rem auto' : '0 0 5rem 0')};
  margin: 3rem auto;

  @media (max-width: ${theme.breakpoint.mobile}) {
    // margin: ${(props) => (props.isDetail ? '1rem auto' : '0 0 2rem 0')};
    margin: 1rem auto;
  }
`

const StyledGalleryPost = styled(StyledPost)`
  cursor: pointer;
`

const StyledEvent = styled(Event)`
  border-radius: 0;
  overflow: hidden;
  // margin: ${(props) => (props.isDetail ? '3rem auto' : '0 0 2rem 0')};
  margin: 3rem 0;
  align-self: flex-end;
  @media (max-width: ${theme.breakpoint.mobile}) {
    // margin: ${(props) => (props.isDetail ? '1rem auto' : '0 0 2rem 0')};
    margin: 1rem auto;
  }
`

const StyledPage = styled.div``

const StyledStandard = styled(Standard)`
  .title {
    margin-top: 4rem;
    padding: 1rem 2.5rem 1rem 4rem;
    &.detail {
      margin-top: 4rem;
      padding: 1rem 2.5rem 1rem 4rem;
    }
  }
  .standard-children {
    display: flex;
  }
  .content {
    align-self: flex-end;
    padding: 0 2.5rem 0 6rem;
    // max-width: ${theme.contentMaxWidth};
    &.detail {
      padding: 0 2.5rem;
    }
  }

  .more {
    margin: 1rem auto 2rem auto;
  }

  @media (max-width: ${theme.breakpoint.mobile}) {
    .title {
      margin-top: 1rem;
      padding: 0.5rem 2.5rem 0 2rem;
      &.detail {
        margin-top: 0rem;
      }
    }
    .content {
      padding: 0 2rem 0 1rem;
      &.detail {
        padding: 0 0.5rem;
      }
    }
  }
`

const StyledFooter = styled(Footer)`
  .heading {
    padding: 0.5rem 2.5rem 0.5rem 6rem;
  }
  @media (max-width: ${theme.breakpoint.mobile}) {
    .heading {
      padding: 0.5rem 2.5rem 0.5rem 2rem;
    }
  }
`

const Page = ({ level, openUrl, data, detail, pageIndex }) => {
  const [fitsUrl, setFitsUrl] = useState(false)
  const [content, setContent] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const { disclaimer } = useAppState()
  const { url } = data

  useEffect(() => {
    setFitsUrl(location.pathname.indexOf(url) === 1)
    return () => {}
  }, [location, location.pathname])

  const getPages = (sections, detail, level) => {
    // CHILD PAGES
    if (sections) {
      return sections.map((data, idx) => {
        const pageProps = {
          key: data.url,
          url: data.url,
          level: level,
          detail,
          pageIndex: idx,
          data,
          openUrl,
        }
        return <Page {...pageProps} />
      })
    }
    return []
  }

  const onLinkClick = (link) => {
    const split = link.split('/')
    const page1 = split[0]
    const page2 = split[1] || ''
    if (page2) {
      history.push(generatePath(routeNames.page2, { page1, page2 }))
    } else {
      history.push(generatePath(routeNames.page1, { page1 }))
    }
  }

  const getcontent = () => {
    const { template } = data

    // CONTENT
    let content

    switch (template) {
      case 'post': {
        const postProps = {
          ...data,
          url,
          detail,
          disclaimer,
          postIndex: pageIndex,
          onClick: onLinkClick,
          theme: themes.postTheme,
        }
        const hasDetail = !(postProps.text === '' || postProps.shorttext === '')
        const moreBtn =
          !detail && hasDetail ? <Button text="Mehr" size="xs" /> : null

        content = (
          <StyledPost
            isDetail={level > 1}
            {...postProps}
            hasContent={hasDetail}
            btn={moreBtn}
          />
        )
        break
      }
      case 'event': {
        const eventValues = {
          ...data,
          detail,
          theme: themes.eventTheme,
        }
        content = (
          <div className={`page-content page-level-${level}`}>
            <StyledEvent isDetail={level > 1} {...eventValues} />
          </div>
        )
        break
      }
      case 'gallery': {
        if (!detail) {
          const postText = data.shorttext !== '' ? data.shorttext : data.text
          const galleryPostProps = {
            ...data,
            url,
            detail,
            shorttext: postText,
            text: postText,
            hasContent: true,
            postIndex: pageIndex,
            onClick: onLinkClick,
            theme: themes.postTheme,
          }
          content = <StyledGalleryPost isDetail={false} {...galleryPostProps} />
        } else {
          const galleryProps = {
            ...data,
            detail,
          }
          content = <Gallery {...galleryProps} />
        }

        break
      }
      case 'footer': {
        const footerProps = {
          url,
          data,
          ...data,
          onClick: onLinkClick,
          theme: themes.footerTheme,
        }
        content = (
          <StyledFooter {...footerProps}>
            {getPages(data.sections)}
          </StyledFooter>
        )
        break
      }
      case 'welcome': {
        const welcomeProps = {
          id: 'welcome',
          name: 'welcome',
          scrollDownTarget: 'news',
        }
        content = <Welcome {...welcomeProps} />
        break
      }
      default: {
        const standardProps = {
          id: filterUrl(url, 'standard'),
          url,
          detail: fitsUrl,
          maxSections: fitsUrl ? 20 : 3,
          // onClick: onLinkClick,
          moreBtn: (
            <Button
              className="more-btn"
              onClick={() => history.push(url)}
              text="Mehr lesen"
              size="xl"
            />
          ),
          ...data,
        }
        content = (
          <StyledStandard {...standardProps} theme={themes.standardTheme}>
            {getPages(data.sections, detail, level + 1)}
          </StyledStandard>
        )

        break
      }
    }
    return content
  }

  useEffect(() => {
    setContent(getcontent())
    return () => {}
  }, [data, fitsUrl])

  return <StyledPage id={filterUrl(url, 'page', '')}>{content}</StyledPage>
}

Page.propTypes = {
  data: PropTypes.object,
  detail: PropTypes.bool,
  level: PropTypes.number,
  pageIndex: PropTypes.number,
}

Page.defaultProps = {
  data: {},
  detail: false,
  level: 0,
  pageIndex: -1,
}

export default Page
