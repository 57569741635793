import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconButton from 'components/icon-button/IconButton'

const StyledGalleryButton = styled(IconButton)``

const GalleryButton = ({ children, ...rest }) => (
  <StyledGalleryButton {...rest}>{children}</StyledGalleryButton>
)

GalleryButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

GalleryButton.defaultProps = {
  children: null,
}

export default GalleryButton
