/**
 * Created by gk-lab on 14.08.17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ANI_DIR = {
  LEFT: 'left',
  RIGHT: 'right',
}

const StyledSocialButton = styled.a`
  margin: 0;
  width: 3rem;
  height: 3rem;
  display: flex;
  position: relative;
  pointer-events: all;
  align-items: center;
  margin: 0.25rem 0;
  &:hover {
    span {
      opacity: 0;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    overflow: hidden;
  }
  @media (min-width: ${(props) => props.theme.breakpoint.tablet}) {
    &:hover {
      span {
        opacity: 1;
        right: ${(props) => (props.aniDir === ANI_DIR.LEFT ? '4rem' : 'unset')};
        left: ${(props) => (props.aniDir === ANI_DIR.RIGHT ? '3rem' : 'unset')};
      }
    }
  }
`

const StyledText = styled.span`
  position: absolute;
  text-transform: uppercase;
  padding: 0 0.25rem;
  // line-height: $height;
  opacity: 0;
  right: ${(props) => (props.aniDir === ANI_DIR.LEFT ? '0' : 'unset')};
  left: ${(props) => (props.aniDir === ANI_DIR.RIGHT ? '0' : 'unset')};
  margin: auto 0;
  transition: right 0.4s ease-out, opacity 0.2s, left 0.4s ease-out,
    opacity 0.2s;
`

const StyledImage = styled.img`
  margin: 0;
  display: inline;
  width: 3rem;
  height: 3rem;
`

const SocialButton = ({ href, text, imgSrc, animationDir, className }) => (
  <StyledSocialButton
    aniDir={animationDir}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
  >
    <StyledText aniDir={animationDir}>{text}</StyledText>
    <StyledImage src={imgSrc} alt={text} />
  </StyledSocialButton>
)

export default SocialButton

SocialButton.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  imgSrc: PropTypes.string,
  text: PropTypes.string,
  animationDir: PropTypes.oneOf([ANI_DIR.LEFT, ANI_DIR.RIGHT]),
}

SocialButton.defaultProps = {
  className: '',
  href: '',
  imgSrc: '',
  text: '',
  animationDir: ANI_DIR.LEFT,
}
