import React from 'react'
import PropTypes from 'prop-types'

const SVG = ({ size, path, viewBox, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox={viewBox}
      width={size}
      dangerouslySetInnerHTML={{ __html: path || '' }}
      {...rest}
    />
  )
}

SVG.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewBox: PropTypes.string,
}

SVG.defaultProps = {
  size: 24,
  viewBox: '0 0 24 24',
}

export default SVG
