import Colors from './Colors'

const theme = {
  ...Colors,
  appMaxWidth: '2000px',
  contentMaxWidth: '1000px',
  contentMinWidth: '320px',
  contentFullWidth: '1900px',
  postWidth: '680px',
  zIndex: {
    navigation: 1,
    overlay: 2,
  },
  nav: {
    width: {
      open: '20rem',
      closed: '4rem',
    },
  },
  breakpoint: {
    phone: '480px',
    mobile: '768px',
    tablet: '1024px',
    desktop: '1400px',
    navIsOverlay: '1900px',
    navAlwaysOpen: '1900px',
  },
  vita: {
    card: {
      background: Colors.light,
    },
  },
  post: {
    background: Colors.light,
  },
  card: {
    background: Colors.light,
    color: {
      normal: Colors.secondary,
      hover: Colors.primary,
      active: Colors.primary,
    },
  },
}

export default theme
