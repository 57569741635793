import Event from 'components/event/Event'
import Post from 'components/post/Post'
import Standard from 'pages/standard-v2/Standard'
import Colors from 'Colors'
import GalleryPaginator from 'components/gallery-v2/GalleryPaginator'
import GalleryImage from 'components/gallery-v2/GalleryImage'
import Gallery from 'components/gallery-v2/Gallery'
import Footer from 'pages/footer/Footer'

const eventTheme = {
  ...Event.DefaultTheme,
  maxWidth: '680px',
  display: {
    normal: 'block',
    past: 'block',
  },
  text: {
    background: {
      normal: 'rgba(50,50,50,.8)',
      past: 'rgba(255,255,255,.8)',
    },
    color: {
      normal: '#fff',
      past: '#fff',
    },
    display: {
      normal: 'block',
      past: 'none',
    },
  },
  location: {
    background: {
      normal: 'rgba(30,30,30,.8)',
      past: 'rgba(50,50,50,.8)',
    },
    color: {
      normal: '#fff',
      past: '#fff',
    },
  },
  date: {
    background: {
      normal: 'rgba(255,255,255,1)',
      past: 'rgba(50,50,50,.8)',
    },
    color: {
      normal: '#000',
      past: '#fff',
    },
  },
}

const postTheme = {
  ...Post.DefaultTheme,
  maxWidth: '1024px',
  background: {
    normal: 'rgba(0,0,0,.9)',
    hover: Colors.black,
  },
  color: {
    normal: Colors.white,
    hover: Colors.accent,
  },
  button: {
    background: {
      normal: 'transparent',
      hover: Colors.white,
      active: Colors.white,
    },
    color: {
      normal: Colors.white,
      hover: Colors.black,
      active: Colors.accent,
    },
  },
  thumb: {
    width: '40%',
  },
}

const standardTheme = {
  ...Standard.defaultTheme,
  maxWidth: '1024px',
  background: {
    normal: 'transparent',
    detail: '#111',
  },
  title: {
    background: '#fff',
    color: '#000',
  },
}

const footerTheme = {
  ...Footer.defaultTheme,
  maxWidth: '1024px',
}

const galleryTheme = {
  ...Gallery.defaultTheme,
  paginator: GalleryPaginator.defaultTheme,
  gridImage: {
    background: '#111',
    border: '1px solid #000',
    hover: {
      background: '#1e1e1e',
      border: '1px solid #111',
    },
  },
  slideImage: {
    ...GalleryImage.defaultTheme,
    background: '#000',
    border: 0,
    hover: {
      background: '#000',
      border: 0,
    },
  },
}

export default {
  eventTheme,
  postTheme,
  standardTheme,
  galleryTheme,
  footerTheme,
}
