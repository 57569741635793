import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Picture from 'components/picture/Picture'
import GalleryImage from './GalleryImage'
import { useResponsiveState } from 'context/ResponsiveContext'

const StyledGallery = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`

const StyledGalleryImage = styled(GalleryImage)`
  cursor: pointer;
  width: ${(props) => props.mySize};
  height: ${(props) => props.mySize};
`

const StyledGalleryImageMore = styled(StyledGalleryImage)`
  padding: 0.5rem;
`

const StyledMore = styled.div`
  border: 1px solid #eaeaea;
  padding: 1rem;
  height: 100%;
`

const GalleryGrid = React.forwardRef((props, ref) => {
  const { images, isCropped, galleryTheme, galleryWidth } = props
  const [loadPointer, setLoadPointer] = useState(0)
  const [timerInt, setTimerInt] = useState(-1)
  const [gridImages, setGridImages] = useState([])
  const { breakpoints } = useResponsiveState()

  const getImage = (image, idx, size, isLast) => {
    if (isLast) {
      return (
        <StyledGalleryImageMore
          {...image}
          key="Ìmage-more"
          theme={galleryTheme.gridImage}
          mySize={`${size}px`}
        >
          <StyledMore>
            <h4>MORE</h4>
          </StyledMore>
        </StyledGalleryImageMore>
      )
    }

    return (
      <StyledGalleryImage
        {...image}
        pos={Picture.POSITION.top}
        key={`Ìmage-${idx.toString()}`}
        load
        theme={galleryTheme.gridImage}
        mySize={`${size}px`}
      />
    )
  }

  const initImages = () => {
    if (breakpoints && galleryWidth !== 0) {
      const gridColumns =
        (breakpoints.desktopSm && 5) || (breakpoints.mobileLg && 4) || 2
      const imageSize = Math.floor(galleryWidth / gridColumns)
      setGridImages(
        images.map((image, idx) =>
          getImage(
            image,
            idx,
            imageSize,
            isCropped ? idx === images.length - 1 : false
          )
        )
      )
    }
  }

  useEffect(() => {
    initImages()
    return () => {}
  }, [breakpoints, galleryWidth])

  useEffect(() => {
    if (loadPointer < images.length) {
      setTimerInt(
        setTimeout(() => {
          setLoadPointer(loadPointer + 1)
        }, 500)
      )
      return () => {
        clearInterval(timerInt)
      }
    }
    return () => {}
  }, [loadPointer])

  return <StyledGallery ref={ref}>{gridImages}</StyledGallery>
})

GalleryGrid.propTypes = {
  images: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isCropped: PropTypes.bool,
  galleryTheme: PropTypes.object.isRequired,
  galleryWidth: PropTypes.number,
}

GalleryGrid.defaultProps = {
  images: [],
  isCropped: false,
  galleryWidth: 0,
}

export default GalleryGrid
