import React, { useEffect, useState } from 'react'
import KirbyLoader from 'components/kirby-loader/KirbyLoaderV2'
import styled, { ThemeProvider } from 'styled-components'
import { Route, useHistory } from 'react-router'
import theme from './theme'
import GlobalStyle from './GlobalStyle'
import { RouteRenderer } from '@gk-lab/routerenderer'
// import { ScrollToProvider, SectionsRenderer } from 'src/sectionsrenderer'
import { ScrollToProvider, SectionsRenderer } from 'sectionsrenderer'
import Meta from 'components/meta/Meta'
import Navigation from 'components/navigation/Navigation'
import Page from 'components/page/Page'
import { useAppState } from './context/AppContext'

const kirbyUrl =
  process.env.NODE_ENV === 'development'
    ? // ? 'http://localhost/best-of-nu-metal.de/kirby-v2/api'
      //'http://localhost/music-monks/kirby-v2/api'
      'https://www.music-monks.com/kirby-v2/api'
    : // ? 'https://www.music-monks.com/kirby-v2/api'
      '/kirby-v2/api'

const StyledApp = styled.main`
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

const StyledLoadingContainer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const StyledLogo = styled.img`
  width: 128px;
  height: 128px;
`

const getPageData = (sections, url, result = {}) => {
  sections.forEach((s) => {
    if (s.url === url) result = s
    else if (s.sections) result = getPageData(s.sections, url, result)
  })
  return result
}

const getPage = (idx, url, section, detail = false, level = 0) => {
  // console.log('url - getPage: ', url, detail, level)
  return (
    <Page
      key={`section-page-${idx}`}
      url={url}
      detail={detail}
      level={level}
      pageIndex={idx}
      data={section}
    />
  )
}

const addPath = (section, list, typeFilter = []) => {
  const { url, sections, template } = section
  if (url) {
    if (!typeFilter || typeFilter.length <= 0) {
      list.push(url)
    } else if (typeFilter.indexOf(template) >= 0) {
      list.push(url)
    }
  }
  if (sections) {
    sections.map((s) => addPath(s, list, typeFilter))
  }
}

const App = () => {
  const { loaded, sections } = useAppState()
  const [sectionChildren, setSectionChildren] = useState({})
  const history = useHistory()

  const onClose = () => {
    history.goBack()
  }

  useEffect(() => {
    if (loaded) {
      const pageTypes = [
        'standard',
        'welcome',
        'footer',
        'gallery',
        'post',
        'overlay',
      ]
      // Init paths list
      const pathList = []
      if (sections && sections.length > 0) {
        sections.forEach((s) => {
          addPath(s, pathList, pageTypes)
        })
      }
      // console.log('pathList - : ', pathList)
      // Init sections
      const secs = {}
      pathList.forEach((path, idx) => {
        const section = getPageData(sections, path)
        const level = path.split('/').length - 1
        secs[path] = getPage(idx, path, section, level > 0, level)
      })
      // console.log('secs - : ', secs)
      setSectionChildren(secs)
    }

    return () => {}
  }, [sections, loaded])

  return (
    <ThemeProvider theme={theme}>
      <StyledApp isLoading={!loaded}>
        <GlobalStyle />
        {!loaded && (
          <KirbyLoader url={kirbyUrl}>
            <StyledLoadingContainer>
              <StyledLogo src="/img/monks-index-logo.png" />
              <p>
                <small>MUSIC-MONKS.COM</small>
                <br />
                <small>loading...</small>
              </p>
            </StyledLoadingContainer>
          </KirbyLoader>
        )}
        {loaded && sections.length > 0 && (
          <ScrollToProvider>
            <Navigation />
            <SectionsRenderer
              id="main-sections"
              lockScroll={false}
              sections={sections}
              pages={sectionChildren}
            />
            <RouteRenderer pages={sectionChildren} onClose={onClose} />
            <Route
              exact
              path="/"
              render={() => {
                return <Meta />
              }}
            />
          </ScrollToProvider>
        )}
      </StyledApp>
    </ThemeProvider>
  )
}

App.propTypes = {}
App.defaultProps = {}

export default App
