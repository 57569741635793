import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ResponsiveStateContext = React.createContext()
const ResponsiveDispatchContext = React.createContext()

export const defaultBreakpoints = {
  desktopLg: 1400,
  desktopMd: 1300,
  desktopSm: 1200,
  tabletLg: 1040,
  tabletMd: 991,
  tabletSm: 840,
  mobileXl: 800,
  mobileLg: 650,
  mobileMd: 540,
  mobileSm: 400,
  sideBarAsOverlay: 1280,
  sideBarAsBurger: 1000,
}

const ACTIONS = {
  ON_UPDATE: 'ON_UPDATE',
}

const updateValues = () => {
  return {
    type: ACTIONS.ON_UPDATE,
  }
}

function parseBreakpointList(width, breakpoints) {
  const values = {}
  if (breakpoints) {
    /* eslint-disable-next-line */
    for (const key in breakpoints) {
      if (breakpoints[key]) {
        values[key] = width > breakpoints[key]
      }
    }
  }
  return values
}

function ResponsiveReducer(state, action) {
  switch (action.type) {
    case ACTIONS.ON_UPDATE: {
      const w = window.innerWidth
      const h = window.innerHeight
      return {
        ...state,
        innerHeight: h,
        innerWidth: w,
        orientation: h >= w ? 'portrait' : 'landscape',
        breakpoints: parseBreakpointList(w, defaultBreakpoints),
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function useResponsiveState() {
  const context = React.useContext(ResponsiveStateContext)
  if (context === undefined) {
    throw new Error(
      'useResponsiveState must be used within a ResponsiveProvider'
    )
  }
  return context
}

function useResponsiveDispatch() {
  const context = React.useContext(ResponsiveDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useResponsiveDispatch must be used within a ResponsiveProvider'
    )
  }
  return context
}

function ResponsiveProvider({ children }) {
  const [state, dispatch] = React.useReducer(ResponsiveReducer, {})
  const [listening, setListening] = useState(false)
  const onResize = () => {
    dispatch(updateValues())
  }
  useEffect(() => {
    if (!listening) {
      window.addEventListener('resize', onResize, false)
      setListening(true)
      dispatch(updateValues())
    }
    return () => {
      if (listening) {
        window.removeEventListener('resize', onResize, false)
        setListening(false)
      }
    }
  })

  return (
    <ResponsiveStateContext.Provider value={state}>
      <ResponsiveDispatchContext.Provider value={dispatch}>
        {children}
      </ResponsiveDispatchContext.Provider>
    </ResponsiveStateContext.Provider>
  )
}

ResponsiveProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export { ResponsiveProvider, useResponsiveState, useResponsiveDispatch }
