/**
 * Created by gk-lab on 01.08.17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { setActiveSection, useAppDispatch } from 'context/AppContext'

const StyledWelcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  min-height: 100vh;
  width: 100%;
`
const StyledMiddle = styled.div`
  display: flex;
  width: 100%;
  max-width: 900px;
`

const scaleDown = keyframes`
  from {
    opacity: 1;
    transform: scale(1.5);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const scaleUp = keyframes`
  from {
    opacity: 1;
    transform: scale(.75);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
`

const StyledSpacer = styled.div`
  flex-grow: 1;
`

const StyledImage = styled.img``

const StyledInner = styled.div`
  width: fit-content;
  text-align: center;
`

const StyledLogo = styled(StyledImage)`
  width: 100%;
  animation: ${scaleDown} ${(props) => props.aniDuration || '1s'} ease-out 1
    forwards ${(props) => props.aniDelay || '0s'};
`

const StyledClaim = styled(StyledImage)`
  opacity: 0;
  width: fit-content;
  margin-bottom: 1rem;
  animation: ${scaleUp} 1s ease-out 1 forwards
    ${(props) => props.aniDelay || '0s'};
  width: 73%;
`

const StyledAniCon = styled.div`
  height: 4rem;
  width: 4rem;
  margin: 0 auto;
  text-align: center;
`

const bounce = keyframes`
  from {
    margin-top: -2rem;
    opacity: 1;
  }
  to {
    margin-top: 0;
    opacity: .5;
  }
`

const StyledArrowButton = styled((props) => <Link {...props} />)`
  animation: ${bounce} 1s ease-out infinite forwards 2s;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  margin: 0;
  &:hover {
    i {
      color: #fff;
    }
  }
`

const StyledArrow = styled.span`
  i {
    color: #ccc;
    font-size: 48pt;
  }
  text-align: center;
`

const Welcome = (props) => {
  const appDispatch = useAppDispatch()

  const onScrollDown = () => {
    appDispatch(setActiveSection('news'))
  }

  return (
    <StyledWelcome {...props}>
      <StyledSpacer />
      <StyledMiddle className="middle">
        <StyledInner className="inner">
          <StyledLogo
            aniDuration="1.5s"
            src="/img/musicmonks-white-top.png"
            alt="mm-logo"
          />
          <StyledClaim
            aniDelay=".5s"
            src="/img/musicmonks-white-claim.png"
            alt="mm-claim"
          />
        </StyledInner>
      </StyledMiddle>
      <StyledAniCon className="aniCon">
        <div className="fade-in-arrow">
          <StyledArrowButton type="button" to={'#news'} onClick={onScrollDown}>
            <StyledArrow id="scrollArrow" className="bounce">
              <i className="material-icons">&#xE313;</i>
            </StyledArrow>
          </StyledArrowButton>
        </div>
      </StyledAniCon>
    </StyledWelcome>
  )
}

Welcome.propTypes = {
  scrollDownId: PropTypes.string,
  id: PropTypes.string,
}

Welcome.defaultProps = {
  scrollDownId: '',
  id: '',
}

export default Welcome
