import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Picture from '../picture/Picture'

const StyledVideo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 315px;
`

const StyledText = styled.div`
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;

  a,
  p {
    font-size: 1.25rem;
    @media (min-width: 768px) {
      font-size: 1.5rem;
    }
  }
`

const StyledButton = styled.button`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 4rem;
  cursor: pointer;
  align-items: center;
  justify-items: center;
  justify-content: center;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  outline: 0;
  border: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  font-size: 1.5rem;

  white-space: nowrap;

  &:hover,
  &:active,
  &:focus {
    outline: 0;
    background-color: rgba(255, 255, 255, 1);
    color: #000;

    svg {
      fill: black;
    }
  }

  svg {
    fill: white;
    min-width: 1.5rem;
    min-height: 1.5rem;
  }
`

const StyledPicture = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 315px;
  width: 100%;
`

const youtubeFrame = (src, width, height) => (
  <iframe
    width={width}
    height={height}
    src={src}
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
)

const Video = ({
  children,
  youtube,
  thumb,
  width,
  height,
  disclaimer,
  ...rest
}) => {
  const [confirmed, setConfirmed] = useState(false)
  const getVideo = () => {
    if (youtube) {
      return youtubeFrame(youtube, width, height)
    }
  }

  const getDisclaimer = () => {
    if (youtube && disclaimer && disclaimer.youtube) {
      const { copy, link, url } = disclaimer.youtube
      return (
        <>
          {copy && <p className="yt-disclaimer-copy">{copy}</p>}
          {url && (
            <a href={url} target={'_blank'} className="yt-disclaimer-link">
              {link}
            </a>
          )}
        </>
      )
    }
  }

  const confirm = () => {
    setConfirmed(true)
  }

  return (
    <StyledVideo className="yt-disclaimer" {...rest}>
      {!confirmed && (
        <>
          {thumb && <StyledPicture image={thumb} />}
          <StyledButton onClick={confirm}>{children}</StyledButton>
          <StyledText>{getDisclaimer()}</StyledText>
        </>
      )}
      {confirmed && getVideo()}
    </StyledVideo>
  )
}

Video.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  youtube: PropTypes.string,
  disclaimer: PropTypes.shape({
    youtube: PropTypes.shape({
      copy: PropTypes.string,
      link: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
  thumb: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

Video.defaultProps = {
  children: null,
  youtube: '',
  disclaimer: {},
  thumb: '',
  width: '100%',
  height: '315',
}

export default Video
