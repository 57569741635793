import { createGlobalStyle } from 'styled-components'
import Colors from './Colors'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
  body {
    width: 100vw;
    background-color: #000;
    max-width: ${theme.appMaxWidth};
    margin: 0 auto;
  }
  
  html, body, #root {
    overflow-x: hidden;
  }
  
  header {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 100%;
    height: 80px;
    display: inline-block;
  }

  .ytplayer {
    max-width: ${theme.postWidth};
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 25%;
    min-height: 10rem;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
    
  main {
    max-width: ${theme.appMaxWidth};
  }

  div {
    box-sizing: border-box;
  }
  a {
    box-sizing: border-box;
    text-decoration: none;
  }
  
  p, li, h1, h2, h3, h4, h5, h6, a, button {
    font-family: 'Oswald', sans-serif;
    max-width: 100vw;
    box-sizing: border-box;
  }
  
  p {
    font-weight: 300;
    -webkit-margin-before: 0.75em;
    -webkit-margin-after: 0.75em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
  
    i.material-icons {
      font-family: "Material Icons";
      vertical-align: bottom;
      font-size: 1.5rem;
      text-decoration: none !important;
      max-width: 24px;
      font-style: normal;
      margin-bottom: -6px;
      margin-right: 8px;
      display: inline-block;
    }
  }
  
  h1 {
    font-size: 1.5rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .15em;
    display: block;
    width: 100%;
    max-width: 100vw;
  }
  
  h2 {
    font-size: 2.25rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .3em;
    line-height: 2.5rem;
  }
  
  h3 {
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 500;
    line-height: 2.25rem;
  }
  
  h4 {
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: .05em;
    line-height: 1.75rem;
    font-weight: 500;
  }
  
  p, li, a {
    font-size: 1.25rem;
    line-height: 1.75;
  }
  
  p > a {
    text-decoration: none;
    color: ${Colors.accent};
  }
  
  a {
    text-decoration: none;
    &:hover {
      color: ${Colors.dark};
    }
  }
  
  button {
    i {
      margin-right: .5rem;
    }
  }
  
  strong {
    font-weight: 500;
  }
  
  #main-sections {
    margin-left: ${theme.nav.width.closed};
    @media (min-width: ${theme.breakpoint.navAlwaysOpen}) {
      margin-left: ${theme.nav.width.open};
    }
    
    @media (max-width: ${theme.breakpoint.mobile}) {
      margin-left: 0;
    }
  }

  .post {
    border-radius: 0;
    overflow: hidden;
    margin: 0 0 2rem 0;
    max-width: 100%;
    background-color: rgba( 0, 0, 0, 0.65 );
    &.thumb {
      min-height: 15rem;
    }
    
    @media (min-width: ${theme.breakpoint.tablet}) {
      max-width: ${theme.postWidth};
    }
    
    &.detail {
      max-width: 90%;
      margin: 0 auto;
      align-self: center;
    }
      
  }
  
  .route-container-bar, .route-container {
    max-width: ${theme.appMaxWidth};
    margin: 0 auto;
  }
  
  .route-container-bar {
    padding: 0.5rem 1rem;
  }
  
  .standard {
    background-color: transparent !important;
    color: ${Colors.white};
    
    .standard-heading {
      background-color: rgba(255,255,255,0.85) !important;
      color: ${Colors.secondary};
      padding: 1rem 0 1rem 5%;
      margin-top: 6rem !important;
      @media (max-width: ${theme.breakpoint.mobile}) {
        padding-left: 2rem;
      }
      @media (max-width: ${theme.breakpoint.phone}) {
        padding-left: 1rem;
      }
    }
    .standard-content {
      max-width: 98%;
      padding: 0 1%;
      align-self: center;
      @media (min-width: ${theme.breakpoint.phone}) {
        max-width: 90%;
        padding: 0 5%;
      }
    }
    
    .standard-children {
      align-self: center;
      
      @media (min-width: ${theme.breakpoint.phone}) {
        // max-width: calc(100% - 2rem);
      }
      @media (min-width: ${theme.breakpoint.mobile}) {
        // max-width: 100;
      }
      @media (min-width: ${theme.breakpoint.desktop}) {
        // max-width: calc(100% - 6rem);
      }
    }
    
    &.detail {
      background-color: #151515 !important;
      // max-width: ${theme.appMaxWidth};
      margin: 0 auto;
      min-height: 100vh;
      .standard-content {
        width: 100%;
        max-width: ${theme.contentMaxWidth};
        padding: 0 5%;
        margin: 0 auto;
      }
      .standard-children {
      
      }
      .post {
        max-width: ${theme.contentMaxWidth};
      }
      .event {
        max-width: ${theme.contentMaxWidth};
      }
      
    }
  }
    
  #news {
    .standard-children {
      // max-width: ${theme.postWidth};
      align-self: flex-start;
    }
  }
  
  #info-section-stage, #media-section-stage {
    min-height: 100vh;
  }
  
  #info {
    .standard{
      justify-content: space-between;
      min-height: 100vh;
      .standard-children {
        align-self: center;
      }
      .post {
        max-width: calc(100% - 1rem);
        @media (min-width: ${theme.breakpoint.tablet}) {
          max-width: calc(100% - 4rem);
        }
      }
    }
  }
  #dates {
    .standard{
      .standard-children {
        align-self: flex-end;
      }
    }
  }
  
  #weiteres {
    .content {
      padding: 0 1rem;
      @media (min-width: ${theme.breakpoint.phone}) {
        padding: 0 8rem;
      }
    }
    .standard{
      .standard-children {
        
      }
    }
  }
  
  #kontakt-booking-standard, #kontakt-mail-standard  {
    .standard-heading {
      padding: 1rem 2rem;
    }
  }

  #kontakt-section-content, #media-section-content {
    min-height: 55vh;
  }
  
  .yt-disclaimer {
    p, a {
      font-size: 1em !important;
    }
    @media (min-width: ${theme.breakpoint.phone}) {
      p, a {
        font-size: 1em !important;
      }
    }
  }
    
`

export default GlobalStyle
