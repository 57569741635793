/**
 * Created by gk-lab on 01.08.17.
 */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import StagePicture from '../StagePicture/StagePicture'
import styled from 'styled-components'
import {
  useScrollToDispatch,
  initializeSection,
  useScrollToState,
  setScrollTo,
  setIsScrolling,
} from '../ScrollToContext/ScrollToContext'

const StyledSection = styled.section`
  display: inline-block;
  width: 100%;
`

const StyledPicture = styled(StagePicture)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0;
  margin: 0;
`

const StyledStage = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
`

const StyledContent = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const Section = ({ idx, image, url, children, ...rest }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [loadImage, setLoadImage] = useState(false)
  const [pictureProps, setPictureProps] = useState({})
  const ref = useRef(null)
  const scrollToDispatch = useScrollToDispatch()
  const { activeUrl, scrollUrl, scrollPos, isScrolling } = useScrollToState()

  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (ref && ref.current) {
      const isIn =
        scrollPos * -1 + 200 > ref.current.offsetTop - window.innerHeight
      // console.log('Section - : ', activeUrl, url, scrollPos, isIn, loadImage)
      if (isIn) {
        if (!isVisible) {
          setIsVisible(true)
          if (!loadImage && image) {
            setLoadImage(true)
            setPictureProps({
              image: image.url,
              ...(image.url_md_p && { image_md_p: image.url_md_p }),
              ...(image.url_md_l && { image_md_l: image.url_md_l }),
              ...(image.url_sm_p && { image_sm_p: image.url_sm_p }),
              ...(image.url_sm_l && { image_sm_l: image.url_sm_l }),
              ...(image.url_md && { image_md: image.url_md }),
              ...(image.url_sm && { image_sm: image.url_sm }),
              ...(image.align && { align: image.align }),
            })
          }
        }
      }
    } else if (isVisible) {
      setIsVisible(false)
    }
    return () => {}
  }, [ref, activeUrl, scrollPos, isVisible, image, loadImage])

  useEffect(() => {
    if (ref && ref.current) {
      scrollToDispatch(initializeSection(idx, url, ref.current))
    }
    return () => {}
  }, [ref, ref.current, url, idx])

  useEffect(() => {
    // console.log("Section becomes active ? : ", activeUrl, url, scrollUrl);
    if (location.pathname === '/') {
      if (activeUrl === url) {
        // console.log('Im active - : ', url)
        if (scrollUrl !== url) {
          history.replace(`#${url}`)
          scrollToDispatch(setIsScrolling(true))
          scrollToDispatch(setScrollTo(ref.current.offsetTop))
        }
      }
    }
    return () => {}
  }, [location.pathname, url, activeUrl, scrollUrl, ref && ref.current])

  useEffect(() => {
    if (!isScrolling) {
      if (scrollUrl === url && location.pathname === '/') {
        // console.log('set my hash! : ', url)
        history.replace(`#${url}`)
      }
    }
    return () => {}
  }, [isScrolling, scrollUrl, url, location.pathname])

  return (
    <StyledSection id={url} isVisible={true} ref={ref} {...rest}>
      <StyledStage id={`${url}-section-stage`}>
        <StyledPicture id={`${url}-section-picture`} {...pictureProps} />
        <StyledContent id={`${url}-section-content`}>{children}</StyledContent>
      </StyledStage>
    </StyledSection>
  )
}

Section.propTypes = {
  idx: PropTypes.number.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    url_md_p: PropTypes.string,
    url_md_l: PropTypes.string,
    url_sm_p: PropTypes.string,
    url_sm_l: PropTypes.string,
    url_md: PropTypes.string,
    url_sm: PropTypes.string,
    align: PropTypes.string,
  }),
  url: PropTypes.string.isRequired,
}

Section.defaultProps = {
  image: {},
}

export default Section
