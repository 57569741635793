import smoothscroll from 'smoothscroll-polyfill'

// kick off the polyfill!
smoothscroll.polyfill()

export {
  ScrollToProvider,
  useScrollToState,
  useScrollToDispatch,
  setScrollTo,
} from './ScrollToContext/ScrollToContext.js'

export { default as SectionsRenderer } from './SectionsRenderer/SectionsRenderer.js'
