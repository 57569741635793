import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'
import GalleryGrid from './GalleryGrid'
import GallerySlide from './GallerySlide'
import GalleryPaginator from './GalleryPaginator'
import GalleryImage from './GalleryImage'
import { ResponsiveProvider } from 'context/ResponsiveContext'

const StyledGallery = styled.div`
  max-width: ${(props) => props.theme.contentMaxWidth};
  margin: 0 auto;
`

const Gallery = ({
  id,
  galleryTheme,
  url,
  title,
  files,
  contentId,
  level,
  previewcount,
  grid,
  className,
  onClose,
  ...rest
}) => {
  const history = useHistory()
  const ref = useRef(null)
  const location = useLocation()
  const [initialHash, setInitialHash] = useState(location.hash)
  const [contentPointer, setContentPointer] = useState(-1)
  const [galleryWidth, setGalleryWidth] = useState(0)
  const [content, setContent] = useState(null)
  const gridRef = useRef()

  const updateWidth = () => {
    if (ref && ref.current) {
      setGalleryWidth(ref.current.offsetWidth)
    }
  }

  const getContentPointer = () => {
    const hashPointer = (history.location.hash || '#').split('#').join('')
    if (hashPointer) {
      const cIdx = Number(hashPointer)
      if (cIdx && cIdx >= 0) {
        return cIdx >= 1 && cIdx < files.length + 1 ? cIdx - 1 : -1
      }
    }
    return -1
  }

  const onGridClick = (file = undefined, idx = -1) => {
    if (file && idx >= 0) {
      history.push(`/${url}#${idx + 1}`)
    } else {
      history.push(`/${url}`)
    }
  }

  const onPreviewClick = () => {
    history.push(`/${url}`)
  }

  const onSlideClick = (file = undefined, idx = -1) => {
    if (file && idx >= 0) {
      history.replace(`/${url}#${idx + 1}`)
    } else {
      history.replace(`/${url}`)
    }
  }

  const getImageFromFile = (file, idx, clickMethod) => ({
    ...file,
    idx: Number(idx),
    onClick: () => clickMethod(file, idx),
  })

  const getImages = (clickMethod = () => {}, count = 0) => {
    const data =
      count > 0 && count < files.length ? files.slice(0, count) : files
    return data.map((file, idx) => getImageFromFile(file, idx, clickMethod))
  }

  const getContent = () => {
    if (contentPointer === -1) {
      const maxCount = Number(previewcount)
      const isPreview = level === 1 && !Number.isNaN(maxCount) && !grid
      const gridImages = isPreview
        ? getImages(onPreviewClick, maxCount)
        : getImages(onGridClick, -1)
      const isCropped = gridImages.length !== files.length
      if (isCropped) {
        gridImages.push({
          idx: -1,
          onClick: () => onGridClick(),
        })
      }
      return (
        <>
          {title && <h4 className="page-title gallery-title">{title}</h4>}
          <div className="page-content gallery-content">
            <GalleryGrid
              images={gridImages}
              isCropped={isCropped}
              galleryTheme={galleryTheme}
              galleryWidth={galleryWidth}
              ref={gridRef}
            />
          </div>
        </>
      )
    }

    return (
      <GallerySlide
        {...rest}
        images={getImages()}
        onClick={onSlideClick}
        pointer={contentPointer}
        galleryTheme={galleryTheme}
        galleryWidth={galleryWidth}
        onClose={onClose}
      />
    )
  }

  useEffect(() => {
    updateWidth()
    window.addEventListener('resize', updateWidth)
    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [ref])

  useEffect(() => {
    setContentPointer(getContentPointer())
    return () => {}
  }, [history, history.location.hash])

  useEffect(() => {
    if (initialHash) {
      history.replace(`/${url}`)
      history.push(`/${url}${initialHash}`)
      setInitialHash('')
    }
    return () => {}
  }, [initialHash])

  useEffect(() => {
    setContent(getContent())
    return () => {}
  }, [
    files,
    contentPointer,
    level,
    grid,
    previewcount,
    galleryWidth,
    gridRef.current,
  ])

  return (
    <ResponsiveProvider>
      <StyledGallery className={`${className} page gallery`} id={id} ref={ref}>
        {content}
      </StyledGallery>
    </ResponsiveProvider>
  )
}

Gallery.defaultTheme = {
  paginator: GalleryPaginator.defaultTheme,
  gridImage: GalleryImage.defaultTheme,
  slideImage: GalleryImage.defaultTheme,
  slide: GallerySlide.defaultTheme,
}

Gallery.propTypes = {
  id: PropTypes.string,
  galleryTheme: PropTypes.object,
  level: PropTypes.number,
  template: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
  contentId: PropTypes.string,
  className: PropTypes.string,
  files: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  previewcount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  gridColumns: PropTypes.number,
  grid: PropTypes.bool,
  onClose: PropTypes.func,
}

const ea = []

Gallery.defaultProps = {
  id: 'gallery',
  galleryTheme: Gallery.defaultTheme,
  level: 0,
  files: ea,
  title: '',
  className: '',
  contentId: '',
  previewcount: 0,
  gridColumns: 2,
  grid: false,
  onClose: undefined,
}

export default withRouter(Gallery)
