import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import ReactInputPosition, {
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
  defaultState,
} from 'react-input-position'
import GalleryImage from './GalleryImage'
import Picture from '../picture/Picture'
import GalleryButton from './GalleryButton'
import GalleryPaginator from './GalleryPaginator'

const StyledGallerySlide = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: ${(props) => props.theme.background};
  display: flex;
  justify-content: center;
  max-width: ${(props) => props.theme.contentMaxWidth};
  overflow: hidden;
`

const StyledImagesContainer = styled.div`
  position: absolute;
  transition: ${(props) => (props.animate ? 'left .6s' : 'none')};
  top: 0;
  bottom: 0;
  height: 100%;
  min-width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  margin: 0 auto;
`

const StyledImage = styled(GalleryImage)`
  height: 100%;
  width: ${(props) => `${props.myWidth}px`};
  padding: 0;
  pointer-events: none;
`
const StyledButtons = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
  padding: 0.5rem;
  pointer-events: none;
`

const StyledPrevButton = styled(GalleryButton)`
  justify-self: left;
  pointer-events: auto;
`

const StyledNextButton = styled(GalleryButton)`
  justify-self: right;
  pointer-events: auto;
`

const GallerySlide = ({
  images,
  descriptions,
  copyrights,
  pointer,
  onClick,
  galleryTheme,
  galleryWidth,
  ...rest
}) => {
  const [mouseValues, setMouseValues] = useState(defaultState)
  const [left, setLeft] = useState(0)
  const [animate, setAnimate] = useState(false)
  const [initialPointer, setInitialPointer] = useState(pointer)
  const [content, setContent] = useState(null)
  const [elementDim, setElementDim] = useState({ width: 0, height: 0 })

  const getImage = (image, idx, load) => {
    return (
      <StyledImage
        {...image}
        pos={Picture.POSITION.center}
        fit={Picture.FILLMODE.contain}
        key={`Image-${idx.toString()}`}
        load={load}
        myWidth={galleryWidth}
        theme={galleryTheme.slideImage}
      >
        {descriptions && descriptions[idx] && <span>descriptions[idx]</span>}
        {copyrights && copyrights[idx] && <span>copyrights[idx]</span>}
      </StyledImage>
    )
  }

  useEffect(() => {
    setContent(
      images.map((image, idx) =>
        getImage(image, idx, Math.abs(idx - pointer) < 2)
      )
    )
    return () => {}
  }, [pointer, galleryWidth])

  useEffect(() => {
    if (initialPointer !== -1) {
      setAnimate(initialPointer !== pointer)
      setInitialPointer(-1)
    } else {
      return () => {
        setAnimate(true)
      }
    }
    return () => {}
  }, [initialPointer, pointer])

  const prev = (touched = false) => {
    if (pointer > 0) {
      onClick(images[pointer - 1], pointer - 1)
    } else if (!touched) {
      onClick(images[images.length - 1], images.length - 1)
    }
  }

  const next = (touched = false) => {
    if (pointer >= images.length - 1) {
      if (!touched) onClick(images[0], 0)
    } else {
      onClick(images[pointer + 1], pointer + 1)
    }
  }

  const onUpdate = (stateChanges) => {
    setAnimate(
      !(
        elementDim.width !== stateChanges.elementDimensions.width ||
        elementDim.height !== stateChanges.elementDimensions.height
      )
    )
    setMouseValues(stateChanges)
    setElementDim(stateChanges.elementDimensions)
  }

  useEffect(() => {
    const { itemPosition, active } = mouseValues
    const { x } = itemPosition
    let diffX = x * 2
    if (!active) {
      if (Math.abs(x) > galleryWidth * 0.1) {
        if (x > 0) {
          prev(true)
        } else {
          next(true)
        }
        setMouseValues(defaultState)
      }
      diffX = 0
    }
    setLeft(pointer * galleryWidth - diffX)
    return () => {}
  }, [mouseValues, pointer, galleryWidth, animate])

  return (
    <ThemeProvider theme={galleryTheme.slide}>
      <StyledGallerySlide {...rest}>
        <ReactInputPosition
          mouseActivationMethod={MOUSE_ACTIVATION.MOUSE_DOWN}
          touchActivationMethod={TOUCH_ACTIVATION.TOUCH}
          onUpdate={onUpdate}
          cursorStyle="default"
          trackPreviousPosition
          trackItemPosition
          centerItemOnActivate
          style={{ width: '100%', height: '100%' }}
        >
          <StyledImagesContainer
            style={{ left: `${-left}px` }}
            animate={animate}
          >
            {content}
          </StyledImagesContainer>
        </ReactInputPosition>
        <StyledButtons className="gallery-buttons">
          {pointer > 0 && (
            <StyledPrevButton icon="keyboard_arrow_left" onClick={prev} />
          )}
          <div style={{ flexGrow: 2 }} />
          {pointer < images.length - 1 && (
            <StyledNextButton icon="keyboard_arrow_right" onClick={next} />
          )}
        </StyledButtons>
        <GalleryPaginator
          images={images}
          pointer={pointer}
          theme={galleryTheme.paginator}
        />
      </StyledGallerySlide>
    </ThemeProvider>
  )
}

GallerySlide.defaultTheme = {
  background: '#000',
}

GallerySlide.propTypes = {
  images: PropTypes.array,
  pointer: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  descriptions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  copyrights: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  galleryTheme: PropTypes.object.isRequired,
  galleryWidth: PropTypes.number,
}

GallerySlide.defaultProps = {
  images: [],
  descriptions: [],
  copyrights: [],
  onClick: () => {},
  galleryWidth: 0,
}

export default GallerySlide
