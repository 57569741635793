import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Section from '../Section/Section'
import ScrollToContainer from './ScrollToContainer'

const StyledSectionsRenderer = styled(ScrollToContainer)`
  margin-left: 0;
  transition: margin-left 0.2s;
  display: inline-block;
  width: 100%;
  overflow-y: ${(props) => (props.lockScroll ? 'hidden' : 'auto')};
`

const SectionsRenderer = ({ sections, pages, ...rest }) => {
  const [sectionComponents, setSectionComponents] = useState([])

  useEffect(() => {
    if (sections && sections.length > 0) {
      setSectionComponents(
        sections.map((section, idx) => {
          const { url, image, ...props } = section
          const sectionProps = {
            idx: idx,
            key: idx.toString(),
            url: url,
            image: image.url ? image : { url: '' },
            ...props,
          }
          return (
            <Section {...sectionProps}>
              {pages && pages[url] && pages[url]}
            </Section>
          )
        })
      )
    }
    return () => {}
  }, [sections, pages])

  if (sectionComponents.length > 0) {
    return (
      <StyledSectionsRenderer {...rest}>
        {sectionComponents}
      </StyledSectionsRenderer>
    )
  }
  return null
}

SectionsRenderer.propTypes = {
  sections: PropTypes.array.isRequired,
  pages: PropTypes.object.isRequired,
  lockScroll: PropTypes.bool,
}

SectionsRenderer.defaultProps = {
  lockScroll: false,
}

export default SectionsRenderer
