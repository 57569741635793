/**
 * Created by gk-lab on 25.08.17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button/Button'
import styled, { ThemeProvider } from 'styled-components'

/* eslint-disable react/no-danger */

const StyledStandard = styled.div`
  display: flex;
  flex-direction: column;
  //flex-wrap: wrap;
  height: 100%;
  width: 100%;
  text-align: left;
  // min-height: ${(props) => props.fullheight && '100vh'};
  color: ${(props) => props.theme.textColor || '#fdfdfd'};
  background: ${(props) =>
    (props.isDetail && props.theme.background.detail) ||
    props.theme.background.normal};
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem;
`

const StyledChildrenContainer = styled.ul`
  padding: 0;
  display: inline;
  width: 100%;
`

const StyledTitle = styled.h2`
  min-width: 100%;
  padding: 0 2.5rem;
  background: ${(props) =>
    (props.isDetail && props.theme.title.background) ||
    props.theme.title.background};
  color: ${(props) =>
    (props.isDetail && props.theme.title.color) || props.theme.title.color};
`

const StyledMoreContainer = styled.div`
  display: flex;
  max-width: ${(props) => props.theme.maxWidth};
`

const StyledText = styled.div`
  padding: 0 2.5rem;
`

const getChildren = (detail, children, max = 3) => {
  if (detail) {
    return children
  }

  const childs = []
  for (let i = 0; i <= children.length; i += 1) {
    if (i < max) {
      childs.push(children[i])
    }
  }
  return childs
}

const Standard = ({
  url,
  title,
  text,
  children,
  onClick,
  detail,
  moreText,
  fullheight,
  theme,
  maxSections,
}) => {
  const content = getChildren(detail, children, maxSections)
  const moreContent = content.length < children.length

  const getClassName = (name) => {
    const cl = [name]
    if (detail) cl.push('detail')
    return cl.join(' ')
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledStandard
        id={`${url}-standard`}
        isDetail={detail}
        fullheight={fullheight}
      >
        {title && (
          <StyledTitle
            className={getClassName('title page-title')}
            isDetail={detail}
          >
            {title}
          </StyledTitle>
        )}
        <StyledContent
          className={getClassName('content page-content')}
          isDetail={detail}
        >
          {text !== '' && (
            <StyledText
              className={getClassName('text')}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {content && (
            <StyledChildrenContainer className={getClassName('children')}>
              {content}
              {moreContent && (
                <StyledMoreContainer
                  className={getClassName('more page-more-btn')}
                >
                  <Button
                    className="more-btn"
                    onClick={() => onClick(url)}
                    text={moreText}
                    size="xl"
                  />
                </StyledMoreContainer>
              )}
            </StyledChildrenContainer>
          )}
        </StyledContent>
      </StyledStandard>
    </ThemeProvider>
  )
}

Standard.defaultTheme = {
  maxWidth: '768px',
  background: {
    normal: 'transparent',
    detail: 'rgba(0,0,0,.7)',
  },
  title: {
    background: '#fff',
    color: '#000',
  },
  textColor: '#fdfdfd',
}

Standard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  detail: PropTypes.bool,
  moreText: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
  fullheight: PropTypes.bool,
  theme: PropTypes.object,
  maxSections: PropTypes.number,
}

Standard.defaultProps = {
  children: [],
  detail: false,
  fullheight: true,
  moreText: '',
  className: '',
  onClick: () => {},
  text: '',
  title: '',
  url: '',
  theme: Standard.defaultTheme,
  maxSections: 3,
}

export default Standard
