/**
 * Created by gk-lab on 25.08.17.
 */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'

const StyledEventItem = styled.li`
  display: ${(props) =>
    props.isPast ? props.theme.display.past : props.theme.display.normal};
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1rem;
  margin-bottom: 1.5rem;
  margin-left: 0;
  padding: 0;
  text-align: right;
  width: 100%;
  max-width: ${(props) => props.theme.maxWidth};
`

const StyledEvent = styled.div``

const StyledHeading = styled.h4`
  letter-spacing: 0.05em;
  margin: 0;
  display: block;
`

const StyledDate = styled(StyledHeading)`
  background-color: ${(props) =>
    props.isPast
      ? props.theme.date.background.past
      : props.theme.date.background.normal};
  color: ${(props) =>
    props.isPast ? props.theme.date.color.past : props.theme.date.color.normal};
  padding: 1rem;
  display: block;
`

const StyledLocation = styled(StyledHeading)`
  background-color: ${(props) =>
    props.isPast
      ? props.theme.location.background.past
      : props.theme.location.background.normal};
  color: ${(props) =>
    props.isPast
      ? props.theme.location.color.past
      : props.theme.location.color.normal};
  padding: 1rem;
`

const StyledText = styled.div`
  padding: 1rem;
  background-color: ${(props) =>
    props.isPast
      ? props.theme.text.background.past
      : props.theme.text.background.normal};
  display: ${(props) =>
    props.isPast
      ? props.theme.text.display.past
      : props.theme.text.display.normal};
  p,
  a {
    margin: 0;
    padding: 0;
    color: ${(props) =>
      props.isPast
        ? props.theme.text.color.past
        : props.theme.text.color.normal};
  }
`

const Event = ({ date, past, location, text, theme, ...rest }) => {
  const ds1 = date.split(' ')
  const ds2 = ds1[0].split('-')
  // 2017-08-26 17:30
  const /* time = ds1[1], */ year = ds2[0]

  const month = ds2[1]
  const day = ds2[2]

  let dateStr
  if (past) {
    dateStr = `${month}.${year}`
  } else {
    /* dateStr = day + "." + month + "." + year + " - " + time; */
    dateStr = `${day}.${month}.${year}`
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledEventItem
        isPast={past}
        className={`event-item ${past && ' past'}`}
        {...rest}
      >
        <StyledEvent isPast={past} className="event">
          {date && (
            <StyledDate isPast={past} className="event-date">
              {dateStr}
            </StyledDate>
          )}
          {location && (
            <StyledLocation
              isPast={past}
              className="event-location"
            >{`${location} `}</StyledLocation>
          )}
          {text && (
            <StyledText
              isPast={past}
              className="event-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </StyledEvent>
      </StyledEventItem>
    </ThemeProvider>
  )
}

Event.DefaultTheme = {
  maxWidth: '768px',
  display: {
    normal: 'block',
    past: 'none',
  },
  text: {
    background: {
      normal: 'rgba(255,255,255,.8)',
      past: 'rgba(255,255,255,.3)',
    },
    color: {
      normal: '#333',
      past: '#fff',
    },
    display: {
      normal: 'block',
      past: 'none',
    },
  },
  location: {
    background: {
      normal: 'rgba(0,0,0,.8)',
      past: 'rgba(0,0,0,.5)',
    },
    color: {
      normal: '#fff',
      past: '#fff',
    },
  },
  date: {
    background: {
      normal: 'rgba(255,255,255,1)',
      past: 'rgba(255,255,255,.8)',
    },
    color: {
      normal: '#000',
      past: '#000',
    },
  },
}

Event.propTypes = {
  date: PropTypes.string,
  past: PropTypes.bool,
  location: PropTypes.string,
  text: PropTypes.string,
  /* eslint-disable-next-line */
  theme: PropTypes.object,
}

Event.defaultProps = {
  date: '',
  past: false,
  location: '',
  text: '',
  theme: Event.DefaultTheme,
}

export default Event
