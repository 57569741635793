/**
 * Created by gk-lab on 25.08.17.
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import IconButton from 'components/icon-button/IconButton'
/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */

const StyledFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 1rem;
  }
`

const StyledTitle = styled.h3`
  height: auto;
  width: 100%;
  background-color: ${(props) => props.theme.heading.background};
  color: ${(props) => props.theme.heading.color};
  padding: 1rem;
`

const StyledContent = styled.div`
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  background: ${(props) => props.theme.background};
  max-width: ${(props) => props.theme.maxWidth};
  align-self: ${(props) => props.alignTo};
  min-height: 55vh;
  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    padding: 2rem 1rem;
  }
`

const StyledList = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const StyledIconButton = styled(IconButton)`
  font-size: 2rem;
`

const Footer = ({ url, title, text, children, onClick, theme, className }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledFooter className={`footer ${className}`}>
        <StyledTitle className="heading">{title}</StyledTitle>
        <StyledContent className="content">
          {text !== '' && (
            <div className="Base" dangerouslySetInnerHTML={{ __html: text }} />
          )}
          {children && (
            <StyledList className="list">
              {React.Children.map(children, (child, index) =>
                child && child.props.data ? (
                  <li key={index.toString()} className="item">
                    <StyledIconButton
                      icon="link"
                      id={`a-${url}`}
                      onClick={() => onClick(child.props.data.url)}
                    >
                      {child.props.data.title}
                    </StyledIconButton>
                  </li>
                ) : null
              )}
            </StyledList>
          )}
        </StyledContent>
      </StyledFooter>
    </ThemeProvider>
  )
}

Footer.defaultTheme = {
  maxWidth: '768px',
  heading: {
    background: '#fff',
    color: '#000',
  },
  background: 'transparent',
}

Footer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  text: PropTypes.any,
  title: PropTypes.any,
  url: PropTypes.any,
  onClick: PropTypes.func,
  theme: PropTypes.object,
  className: PropTypes.string,
}

Footer.defaultProps = {
  children: [],
  text: '',
  title: '',
  url: '',
  onClick: undefined,
  theme: Footer.defaultTheme,
  className: '',
}

export default Footer
