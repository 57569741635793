import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Picture from 'components/picture/Picture'
import Colors from 'Colors'

const StyledGalleryImage = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  background: ${Colors.black};
  &:hover {
    background: ${Colors.dark};
  }
`

const StyledPicture = styled(Picture)``

const GalleryImage = ({
  children,
  url,
  name,
  idx,
  load,
  fit,
  pos,
  theme,
  ...rest
}) => {
  const [loaded, setLoaded] = useState(load)
  useEffect(() => {
    if (load) setLoaded(true)
  }, [load, loaded])

  return (
    <StyledGalleryImage {...rest}>
      {url && (load || loaded) && (
        <StyledPicture
          className="gallery-picture"
          image={url}
          fit={fit}
          pos={pos}
        />
      )}
      {children}
    </StyledGalleryImage>
  )
}

GalleryImage.defaultTheme = {
  background: '#fefefe',
  border: '1px solid #eee',
  hover: {
    background: '#fafafa',
    border: '1px solid #eaeaea',
  },
}

GalleryImage.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  idx: PropTypes.number,
  load: PropTypes.bool,
  fit: PropTypes.oneOf(Object.values(Picture.FILLMODE)),
  pos: PropTypes.oneOf(Object.values(Picture.POSITION)),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  theme: PropTypes.object,
}

GalleryImage.defaultProps = {
  idx: -1,
  url: '',
  name: '',
  load: false,
  fit: Picture.FILLMODE.cover,
  pos: Picture.POSITION.top,
  children: null,
  theme: GalleryImage.defaultTheme,
}

export default GalleryImage
