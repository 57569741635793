import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router'

const StyledGalleryPaginator = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  opacity: ${(props) => props.theme.opacity};
  max-width: ${(props) => props.theme.appMaxWidth};
`

const dims = ['1rem', '.75rem', '.5rem']
const StyledDot = styled(({ isActive, ...rest }) => <Link {...rest} />)`
  max-width: ${dims[0]};
  max-height: ${dims[0]};
  min-width: ${dims[0]};
  min-height: ${dims[0]};
  margin: 0.5rem;
  border-radius: 50%;

  @media (max-width: ${(props) => props.theme.breakpoint.mobile}) {
    max-width: ${dims[1]};
    max-height: ${dims[1]};
    min-width: ${dims[1]};
    min-height: ${dims[1]};
  }
  @media (max-width: ${(props) => props.theme.breakpoint.phone}) {
    max-width: ${dims[2]};
    max-height: ${dims[2]};
    min-width: ${dims[2]};
    min-height: ${dims[2]};
  }

  background-color: ${(props) =>
    (props.isActive && props.theme.background.active) ||
    props.theme.background.normal};

  border: ${(props) =>
    (props.isActive && props.theme.border.active) || props.theme.border.normal};

  &:hover {
    background-color: ${(props) =>
      (props.isActive && props.theme.background.active) ||
      props.theme.background.hover};

    border: ${(props) =>
      (props.isActive && props.theme.border.active) ||
      props.theme.border.hover};
  }
  &:focus,
  &:active {
    background-color: ${(props) => props.theme.background.active};
    border: ${(props) => props.theme.background.active};
  }
`

const GalleryPaginator = ({ images, pointer, theme, ...rest }) => {
  const history = useHistory()
  const onClick = (hash, e) => {
    e.preventDefault()
    history.replace(hash)
  }

  const dots = images.map((image, idx) => (
    <StyledDot
      key={`paginator-${idx.toString()}`}
      isActive={idx === pointer}
      to="#"
      onClick={(e) => onClick(`#${idx + 1}`, e)}
    />
  ))

  return (
    <ThemeProvider theme={theme}>
      <StyledGalleryPaginator {...rest}>{dots}</StyledGalleryPaginator>
    </ThemeProvider>
  )
}

GalleryPaginator.defaultTheme = {
  opacity: 0.3,
  background: {
    normal: '#fdfdfd',
    hover: '#fdfdfd',
    active: '#111',
  },
  color: {
    normal: '#111',
    hover: '#222',
    active: '#fdfdfd',
  },
  border: {
    normal: '2px solid #111',
    hover: '2px solid #111',
    active: '2px solid #fdfdfd',
  },
}

GalleryPaginator.propTypes = {
  theme: PropTypes.object,
  images: PropTypes.array,
  pointer: PropTypes.number.isRequired,
}

GalleryPaginator.defaultProps = {
  theme: GalleryPaginator.defaultTheme,
  images: [],
}

export default GalleryPaginator
